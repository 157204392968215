const getUrl = (protocol, type, url, path) => {
    var domain = ""
    if (window.location.hostname.indexOf('localhost') >= 0) {
        return url
    } else if (window.location.hostname.indexOf('dev.suricatoo.com') >= 0) {
        domain = "dev.suricatoo.com"
    } else if (window.location.hostname.indexOf('staging.suricatoo.com') >= 0) {
        domain = "staging.suricatoo.com"
    } else if (window.location.hostname.indexOf('master.suricatoo.com.br') >= 0) {
        domain = "master.suricatoo.com.br"
    } else if (window.location.hostname.indexOf('app.suricatoo.com') >= 0) {
        domain = "app.suricatoo.com"
    } else if (window.location.hostname.indexOf('homolog.suricatoo.com') >= 0) {
        domain = "homolog.suricatoo.com"
    } else if (window.location.hostname.indexOf('dev.minzi.com.br') >= 0) {
        domain = "dev.minzi.com.br"
    } else if (window.location.hostname.indexOf('staging.minzi.com.br') >= 0) {
        domain = "staging.minzi.com.br"
    } else if (window.location.hostname.indexOf('master.minzi.com.br') >= 0) {
        domain = "master.minzi.com.br"
    } else if (window.location.hostname.indexOf('app.minzi.com.br') >= 0) {
        domain = "app.minzi.com.br"
    } else if (window.location.hostname.indexOf('homolog.minzi.com.br') >= 0) {
        domain = "homolog.minzi.com.br"
    } else {
        return url
    }
    return `${protocol}://${type}-${domain}${path}`
}

export default {
    AutenticadorURL: getUrl('https', 'router', window.REACT_APP_URL_AUTENTICADOR, '/auth'),
    BackURL: getUrl('https', 'router', window.REACT_APP_URL_BACK, '/back'),
    RouterUrl: getUrl('https', 'router', window.REACT_APP_URL_ROUTER, ''),
    SocketURL: getUrl('wss', 'router', window.REACT_APP_URL_CLOCK, ''),
    APISocketURL: getUrl('wss', 'api', window.REACT_APP_URL_API_WS, ''),
    ChatURL: getUrl('https', 'router', window.REACT_APP_URL_CHAT, '/api/chat'),
    IntegrationURL: getUrl('https', 'router', window.REACT_APP_URL_ROUTER, '/api/externalIntegration'),
    ApiURL: getUrl('https', 'api', window.REACT_APP_URL_API, '/api'),
    WikiURL: window.REACT_APP_WIKI_URL,
    WikiAppURL: window.REACT_APP_WIKI_APP_URL
}
