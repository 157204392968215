import React from "react"
import {
  NavItem,
  NavLink,
  Dropdown,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
import moment from 'moment-timezone'
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
import classnames from "classnames"
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
import { connect } from 'react-redux'
import { detalhesUsuario } from '../../../redux/actions/cache/pessoaActions'
import { bindActionCreators } from 'redux'
import { logout } from "../../../redux/actions/auth/loginActions"
import url from '../../../Config'
import { history } from "../../../history"
import { IntlContext } from "../../../utility/context/Internationalization"
import ReactCountryFlag from "react-country-flag"
import AvatarPessoa from "../../../utility/AvatarPessoa"
import Notifications from "./Notifications"
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import {
  SpotlightPulse
} from '@atlaskit/onboarding';
import { Cache } from "../../../views/pages/cache/cache"
import FavLink from "../../../views/pages/painel/components/FavLink"
import { Querys } from "../../../database/base"

const UserDropdown = props => {
  return (
    <div className="menu-size">
    <DropdownMenu right>
      {/* <DropdownItem tag="a" href="#">
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.Mail size={14} className="mr-50" />
        <span className="align-middle">My Inbox</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Tasks</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.MessageSquare size={14} className="mr-50" />
        <span className="align-middle">Chats</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.Heart size={14} className="mr-50" />
        <span className="align-middle">WishList</span>
      </DropdownItem> */}
      {/* <DropdownItem divider /> */}
        <DropdownItem
          tag="a"
          href="#"
          onClick={() => {
            if (window.onDeviceActionEvent) window.onDeviceActionEvent('logs')
          }}
        >
          <Icon.AlignLeft size={14} className="mr-50" />
          <span className="align-middle">Logs APP</span>
        </DropdownItem>

        <DropdownItem
          tag="a"
          href="#"
          onClick={() => {
            if (window.onDeviceActionEvent) window.onDeviceActionEvent('update')
          }}
        >
          <Icon.RefreshCcw size={14} className="mr-50" />
          <span className="align-middle">Verificar Atualização APP</span>
        </DropdownItem>

        <DropdownItem
          tag="a"
          href="#"
          onClick={() => {
            if (window.onDeviceActionEvent) window.onDeviceActionEvent('close')
          }}
        >
          <Icon.LogOut size={14} className="mr-50" />
          <span className="align-middle">Fechar APP</span>
        </DropdownItem>

        <hr />

        <DropdownItem
          tag="a"
          href="#"
          onClick={() => {
            props.logout()
            history.push('/pages/login')
          }}
        >
          <Icon.Power size={14} className="mr-50" />
          <span className="align-middle">Deslogar</span>
        </DropdownItem>
        <hr />
        <div className="pt-1 pl-1"><small>Versão Front: 2.0.1.20241217</small></div>
        <div className="pt-1 pl-1"><small>Versão APP: {props.versionApp}</small></div>
        <div className="pt-1 pb-1 pl-1"><small>Device ID: {Cache.deviceID().get()?.substring(0, 10) ?? 'Sem id'}</small></div>
    </DropdownMenu>
      </div>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    suggestions: [],
    locale: 'pt',
    drawerOpen: false,
    versionApp: null
  }

  componentDidMount() {
    axios.get(`${url.RouterUrl}/api/people/me`).then(res => {
      localStorage.setItem('avatarToken', moment().format())
      this.props.detalhesUsuario(res.data)
      if (res && res.data && res.data.Timezone !== moment.tz.guess()) {

      }
    })
    Querys.Person.Me().then(({ data }) => {
      this.setState({ versionApp: data.versionAPP ?? 'Sem versão de app' })
    })
  }

  handleNavbarSearch = () => {
    history.push('/account/config')
  }
  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown })

  avatarPeapleAlt(nome) {
    nome = nome ? nome.toUpperCase() : ''
    const sp = nome.split(' ')
    return sp[0][0] + sp[sp.length - 1][0]
  }

  render() {
    const omTutorial = Cache.omboarding.get()

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">

        {/* <IntlContext.Consumer>
          {context => {
            let langArr = {
              "pt": "Português",
              "en": "English",
            }
            return (
              <Dropdown
                tag="li"
                className="dropdown-language nav-item"
                isOpen={this.state.langDropdown}
                toggle={this.handleLangDropdown}
                data-tour="language"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link"
                >
                  <ReactCountryFlag
                    className="country-flag"
                    countryCode={
                      context.state.locale === "pt"
                        ? "br"
                        : context.state.locale === "en"
                          ? "us"
                          : context.state.locale
                    }
                    svg
                  />
                  <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                    {langArr[context.state.locale]}
                  </span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("en")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="us" svg />
                    <span className="ml-1">English</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("pt")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="br" svg />
                    <span className="ml-1">Portugues</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )
          }}
        </IntlContext.Consumer> */}

        <NavItem className="nav-search" onClick={() => {
          let theme = Cache.theme().get()
          if (theme === 'dark') theme = 'light'
          else theme = 'dark'
          Cache.theme().set(theme)
          window.location.reload()
        }}>
          <NavLink className="nav-link-search" id="suricatoo-theme">
            {Cache.theme().get() === 'light' ? <Icon.Moon size={21} /> : <Icon.Sun size={21} />}
          </NavLink>
        </NavItem>


        <Notifications key='notifications-user' />

        <NavItem className="nav-search" onClick={this.handleNavbarSearch}>
          <NavLink className="nav-link-search" id="suricatoo-settings">
            <Icon.Settings size={21} />
          </NavLink>
        </NavItem>

        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none" style={{ paddingTop: '8px' }}>
              <span className="user-name text-bold-600">
                {this.props.user.user.name}
              </span>
            </div>
            <AvatarPessoa size="md" codigo={this.props.user.user.codigo} nome={this.props.user.user.name} color="black" noTooltip />
          </DropdownToggle>
          <UserDropdown {...this.props} versionApp={this.state.versionApp} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
const mapStateToProps = state => {
  return {
    user: state.auth.login
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({ logout, detalhesUsuario }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser)
