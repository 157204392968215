import React, { Component } from 'react'
import ButtonAtl, { ButtonGroup } from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add'
import { Col, Row } from 'reactstrap';
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { DatePicker, TimePicker } from '@atlaskit/datetime-picker';
import QuillEditor from "../card/Editor"
import './create-card.css'
import SelectPeople from './components/SelectPeople';
import ProjectSelect from './components/ProjectSelect';
import SelectItemType from './components/SelectItemType';
import { Querys } from '../../../database/base';
import ModalSuricatoo from '../../../utility/ModalSuricatoo';
import { connect } from 'react-redux';
import { cardCreated, newError } from '../../../redux/actions/auth/loginActions';
import { onStartActivityAfter } from '../../../redux/reducers/kanban/kanbanAction';
import Select from '@atlaskit/select';
import Lozenge from '@atlaskit/lozenge';
import TextColor from '../../../utility/Color';
import Tooltip from '@atlaskit/tooltip';
import { TimerUtils } from '../../../utility/TimerConvertUtils';
import { newMessageSuccess } from '../../../redux/actions/auth/loginActions';
import {
    SpotlightTarget,
} from '@atlaskit/onboarding';
import OnboardingManager from '../onboarging/OnboardingManager';
import InlineDialog from '@atlaskit/inline-dialog';
import StarIcon from '@atlaskit/icon/glyph/star'
import { Cache } from '../cache/cache';
import { NewMinziEditor } from '../wiki/NewMinziEditor';
import { uuidv4 } from '../../../utility/uuidGenerator';
import '../wiki/main.css'
import Config from '../../../Config';
import { MinziButton } from '../component/minziButton';

const priorities = [
    { value: 0, label: <span className='priority priority-very-high'>Muito Alto</span> },
    { value: 1, label: <span className='priority priority-high'>Alto</span> },
    { value: 2, label: <span className='priority priority-medium'>Médio</span> },
    { value: 3, label: <span className='priority priority-low'>Baixo</span> },
    { value: 4, label: <span className='priority priority-very-low'>Muito Baixo</span> },
    { value: 9, label: <span className='priority priority-no'>Sem prioridade</span> }
]

const user = Cache.user().get()

function getProjectID() {
    return Cache.createCardProject(user?.codigo).get()?.value ?? null
}

function setProjectID(value) {
    return Cache.createCardProject(user?.codigo).set({ value })
}

function getCostCenters() {
    return Cache.createCard(user?.codigo).get()?.costCenter ?? []
}

function getDocumentID() {
    return Cache.createCard(user?.codigo).get()?.documentID ?? uuidv4()
}

function setCostCenters(costCenter) {
    costCenter = costCenter ?? []
    Cache.createCard(user?.codigo).set({ costCenter })
}

const defaultValue = {
    name: '',
    cardType: 'task',
    description: null,
    responsibleID: user?.codigo,
    projectID: getProjectID(),
    costCenterFavCodes: getCostCenters(),
    date: null,
    assigenedToID: user?.codigo,
    activityDescription: '',
    projectValue: null,
    plannedTime: 0,
    startDate: null,
    costCenterID: null,
    costCenterFav: false
}

class CreateCard extends Component {
    state = {
        ...defaultValue,
        open: false,
        dirty: {},
        required: ['name'],
        errors: {},
        cardKey: '',
        success: false,
        saving: false,
        modalStart: false,
        optionsTodos: [],
        sendToKanban: true,
        tagsData: [],
        activeSpotlight: null,
        costCenters: [],
        accountConfig: {},
        costCenterFav: false,
        tags: [],
        documentID: getDocumentID(),
        companyKey: null,
        heightEditor: 59,
        version: 0,
        documentData: null
    }

    toggleModal = () => {
        // if (!this.state.open) {
        //     $('.main-menu').addClass('vertial-layout-index')
        // } else {
        //     $('.main-menu').removeClass('vertial-layout-index')
        // }
        this.setState(prevState => ({
            open: !prevState.open,
            success: false,
            saving: false
        }))
    }

    componentDidMount() {
        if (this.props.forceOpened) {
            this.setState({ open: true })
        }
        if (window.addEventListener) {
            window.addEventListener("message", this.onMessage, false);
        }
        else if (window.attachEvent) {
            window.attachEvent("onmessage", this.onMessage, false);
        }
        this.reset()
    }


    onMessage = (event) => {
        if (event.origin !== Config.WikiAppURL) return;
        var data = event.data;

        let { documentID } = this.state
        if (!documentID) {
            documentID = this.state.active.key
        }

        if (data.documentID !== documentID) return

        if (data.func === "editorRefresh") {
            Querys.Wiki.UpdateDocument(this.state.companyKey, documentID, data.message.document)
            this.setState({ version: this.state.version + 1, documentData: btoa(JSON.stringify(data.message)) })
        }

        if (data.func === "clientHeight" && data.message) {
            const editor = document.getElementById(`document-editor-create`)
            if (editor && editor.style.height !== data.message) {
                editor.style.height = `${data.message + 50}px`
            }
        }

        if (data.func === "clientHeightFocus" && data.message) {
            const editor = document.getElementById(`document-editor-create`)
            if (editor) {
                editor.style.minHeight = `370px`
            }
        }
    }

    toggleModalStartActivity = () => {
        this.setState(prevState => ({
            modalStart: !prevState.modalStart,
            open: !prevState.open
        }))
    }

    udpateValue = (name, values) => {
        const value = {
            ...values,
            dirty: { ...this.state.dirty, [name]: true },
            errors: { ...this.state.errors, [name]: { error: false } }
        }
        this.setState({ ...value })
    }


    save = (startActivity) => {
        if ((this.state.accountConfig?.Config?.COST_CENTER ?? false) && !this.state.costCenterID) {
            this.props.newError({ message: 'Centro de custo é obrigatório' })
            return
        }
        this.setState({ saving: true })
        Querys.Card.Create({ ...this.state, origin: 'card', startActivity })
            .then(({ data }) => {
                if (startActivity) {
                    Querys.Activity.Start(data.cardKey, { startDate: this.state.startDate === '' ? null : this.state.startDate })
                        .then(() => {
                            this.setState({ cardKey: data.cardKey, modalStart: false, open: false, saving: false })
                            this.props.cardCreated()
                            this.props.onStartActivityAfter(data.cardKey)
                        })
                        .catch(() => {
                            this.setState({ cardKey: data.cardKey, modalStart: false, open: false, saving: false })
                            this.props.cardCreated()
                        })
                } else {
                    this.setState({ cardKey: data.cardKey, modalStart: false, open: false, saving: false })
                    this.props.cardCreated()
                }
                const documentID = uuidv4()
                Cache.createCard(user?.codigo).set({ documentID })
                this.setState({ documentID })
                this.props.newMessageSuccess(`Card ${data.cardKey} foi criado com sucesso`)
            })
            .catch(({ data, message }) => this.setState({
                errors: { ...data },
                globalError: message, titleError: 'Falha ao salvar card',
                saving: false,
                modalStart: false,
                open: true
            }))
    }

    reset() {
        const projectID = getProjectID()
        const costCenterFavCodes = getCostCenters()
        this.setState({ costCenterFavCodes, tags: [] })
        const costCenterID = null
        if (projectID > 0) {
            Querys.Project.Tags(projectID).then(({ data }) =>
                this.setState({
                    tagsData: data ?? [],
                    projectID
                }))
        }
        Querys.CostCenter.List().then(({ data }) => {
            this.setState({
                costCenters: data?.filter(x => x.id > 0).map(item => ({ label: item.name, value: item.id })) ?? [],
                costCenterID
            })
        })
        Querys.Account.Config().then(({ data }) => {
            this.setState({ accountConfig: data })
        })
        Querys.Person.Me().then(({ data }) => {
            this.setState({ companyKey: data.company.companyKey })
        })
    }

    render() {
        const costCentersOptions = this.state.costCenterFavCodes.length > 0 ? this.state.costCenterFavCodes.filter(x => this.state.costCenters.findIndex(z => z.value === x.value) >= 0) : this.state.costCenters
        return (
            <div>
                {!this.props.disableCreate &&
                    <SpotlightTarget name='kanban-button-criarCard'>
                        <MinziButton appearance='primary' className='btn-create-card'
                            onClick={() => {
                                this.setState({ ...this.state, ...defaultValue, open: !this.state.open, success: false, saving: false })
                                this.reset()
                            }}
                            iconBefore={<AddIcon label="" size="medium" />}>
                            Criar
                        </MinziButton>
                    </SpotlightTarget>}
                <ModalSuricatoo
                    autoFocus={false}
                    open={this.state.open}
                    //onClose={() => this.setState({ open: !this.state.open })}
                    width='large'
                    layoutControl
                    footer={
                        <div className='d-flex justify-content-between w-100'>
                            <div>
                                <SpotlightTarget name='createCard-iniciar'>
                                    <MinziButton appearance='primary' className='btn-create' isDisabled={this.state.saving}
                                        onClick={() => this.save(true)}>
                                        Iniciar
                                    </MinziButton>
                                </SpotlightTarget>
                            </div>
                            <div>
                                <MinziButton appearance='default' isDisabled={this.state.saving}
                                    onClick={this.toggleModal}>
                                    Cancelar
                                </MinziButton>
                                <SpotlightTarget name='createCard-criar'>
                                    <MinziButton appearance='primary' className='btn-create' isDisabled={this.state.saving}
                                        onClick={() => this.save(false)}>
                                        Criar
                                    </MinziButton>
                                </SpotlightTarget>
                            </div>
                        </div>
                    }>
                    <div>
                        <Row className='mt-1'>
                            <Col md='6'>
                                <h2>Criar card</h2>
                            </Col>
                            <Col md='6'>
                                <div className='float-right mb-2'>
                                    <ButtonGroup>
                                        <SpotlightTarget name='createCard-enviarBacklog'>
                                            <MinziButton
                                                appearance={!this.state.sendToKanban ? 'primary' : 'default'}
                                                onClick={() => this.setState({ sendToKanban: !this.state.sendToKanban })}>Enviar para o backlog</MinziButton>
                                        </SpotlightTarget>
                                        <SpotlightTarget name='createCard-enviarKanban'>
                                            <MinziButton
                                                appearance={this.state.sendToKanban ? 'primary' : 'default'}
                                                onClick={() => this.setState({ sendToKanban: !this.state.sendToKanban })}>
                                                Enviar para o kanban
                                            </MinziButton>
                                        </SpotlightTarget>
                                    </ButtonGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='6'>

                            </Col>
                            <Col md='6'>
                                <SpotlightTarget name='createCard-relator'>
                                    <SelectPeople label='Relator' defaultUserLogin
                                        onChange={responsibleID => this.setState({ responsibleID })} />
                                </SpotlightTarget>
                            </Col>
                            <Col md='12'>
                                <SpotlightTarget name='createCard-selecaoProjeto'>
                                    <ProjectSelect
                                        defaultValue={this.state.projectValue}
                                        cacheValue
                                        onChange={(projectID, projectName, costCenterName, costCenterID) => {
                                            if (projectID !== null) {
                                                this.setState({ projectID, costCenterID, projectValue: { value: projectID, label: projectName } })
                                                Querys.Project.Tags(projectID).then(({ data }) => this.setState({ tagsData: data ?? [] }))
                                                setProjectID(projectID)
                                            } else {
                                                this.setState({ projectID, projectValue: null })
                                                setProjectID(null)
                                            }
                                        }}
                                        onSprintChange={boardID => this.setState({ boardID })}
                                    />
                                </SpotlightTarget>
                            </Col>
                            <Col md='6'>
                                <div className='d-flex justify-content-between' style={{ marginTop: '3px' }}>
                                    <Field label="Centro de custo" name="costcenter-select" isRequired={this.state.accountConfig?.Config?.COST_CENTER ?? false}>
                                        {({ fieldProps }) => { }}
                                    </Field>
                                    <div className='d-flex fav-costcenter'>
                                        <a href='#' className='fav-costcenter-link' onClick={() => this.setState({ costCenterFavCodes: [] })}><small>Carregar todos os centro de custo</small></a>
                                        <InlineDialog placement='right-start'
                                            onClose={() => this.setState({ costCenterFav: false })}
                                            onContentBlur={() => this.setState({ costCenterFav: false })}
                                            isOpen={this.state.costCenterFav}
                                            content={<div style={{ width: '354px' }}>
                                                <h6>Favoritos</h6>
                                                <Select
                                                    isClearable
                                                    className='w-100'
                                                    value={this.state.costCenterFavCodes}
                                                    options={this.state.costCenters}
                                                    placeholder="Escolha os centro de custo favoritos"
                                                    isMulti
                                                    onChange={e => {
                                                        this.setState({ costCenterFavCodes: e ?? [] })
                                                    }}
                                                />
                                                <div className='d-flex justify-content-between'>
                                                    <MinziButton className='mt-1' onClick={() => {
                                                        const costCenterFavCodes = getCostCenters()
                                                        this.setState({ costCenterFavCodes, costCenterFav: false })
                                                    }}>Cancelar</MinziButton>
                                                    <MinziButton className='mt-1' appearance='primary' onClick={() => {
                                                        setCostCenters(this.state.costCenterFavCodes)
                                                        this.setState({ costCenterFav: false })
                                                    }}>Salvar</MinziButton>
                                                </div>
                                            </div>}
                                        >
                                            <Tooltip content='Centro de custo favoritos'>
                                                <MinziButton iconAfter={<StarIcon size='small' />} className='atl-button' appearance='subtle' onClick={() => this.setState({ costCenterFav: true })}></MinziButton>
                                            </Tooltip>
                                        </InlineDialog>
                                    </div>
                                </div>
                                <SpotlightTarget name='createCard-selecaoCentroCusto'>
                                    <Select
                                        isClearable
                                        className='w-100'
                                        value={this.state.costCenters.find(x => x.value === this.state.costCenterID)}
                                        options={costCentersOptions}
                                        placeholder="Escolha o centro de custo"
                                        onChange={e => {
                                            let value = null
                                            if (e) value = e.value
                                            this.setState({ costCenterID: value })
                                        }}
                                    />
                                </SpotlightTarget>
                            </Col>
                        </Row>
                        <div>
                            <Field label="Descrição do card" isRequired name="name">
                                {({ fieldProps }) => (
                                    <Textfield
                                        {...fieldProps}
                                        value={this.state.name === null ? '' : this.state.name}
                                        onChange={e => {
                                            const name = e.target.value
                                            this.udpateValue(fieldProps.name, { name })
                                        }}
                                        placeholder="Preencher com o descrição do card" />
                                )}
                            </Field>

                            <Field label="Documentação" name="example-text">
                                {({ fieldProps }) => (<div></div>)}
                            </Field>

                            <NewMinziEditor
                                id={`document-editor-create`}
                                style={{
                                    height: `${this.state.heightEditor + 10}px`
                                }} card
                                documentID={this.state.documentID}
                                companyKey={this.state.companyKey}
                                documentData={this.state.documentData}
                                version={this.state.version} />

                            <Row>
                                <Col md='6'>
                                    <SelectItemType onChange={cardType => this.setState({ cardType })} />
                                </Col>
                                <Col md='6'>
                                </Col>
                                <Col md='6'>
                                    <Field label="Tags" name="example-tags">
                                        {({ fieldProps }) => (
                                            <Select
                                                className="w-100"
                                                options={this.state.tagsData.map(item => {
                                                    return {
                                                        label: (<Lozenge style={{ backgroundColor: item.color, color: TextColor(item.color) }}>{item.tag}</Lozenge>),
                                                        value: item,
                                                        name: item.tag
                                                    }
                                                })}
                                                filterOption={(option, rawInput) => {
                                                    return option.data.name.toLowerCase().includes(rawInput.toLowerCase())
                                                }}
                                                isMulti
                                                placeholder="Escolha as tags"
                                                isDisabled={this.state.tagsData.length === 0}
                                                onChange={e => {
                                                    this.setState({ tags: e?.map(x => x.value) })
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Col>
                                <Col md='6'>
                                    <Field label="Prioridade" name="example-priority">
                                        {({ fieldProps }) => (
                                            <Select
                                                className="w-100"
                                                options={priorities}
                                                defaultValue={priorities.find(x => x.value === 9)}
                                                placeholder="Prioridade"
                                                onChange={e => { this.setState({ priorityCode: e.value }) }}

                                            />
                                        )}
                                    </Field>
                                </Col>
                                <Col md='6'>
                                    <SpotlightTarget name='createCard-responsavel'>
                                        <SelectPeople label='Responsável' isClearable defaultUserLogin
                                            onChange={assigenedToID => this.setState({ assigenedToID })} />
                                    </SpotlightTarget>
                                </Col>
                                <Col md='6'>
                                    <Field label="Tempo planejado" name="example-tags">
                                        {({ fieldProps }) => (
                                            <Textfield
                                                appearance='standard'
                                                defaultValue={TimerUtils.HoraMinuto.MinutosToHoraMinuto(this.state.plannedTime)}
                                                onChange={e => {
                                                    console.log(parseInt(TimerUtils.tempoToMinutos(e.target.value)))
                                                    this.setState({ plannedTime: parseInt(TimerUtils.tempoToMinutos(e.target.value)) })
                                                }} />
                                        )}
                                    </Field>

                                </Col>
                                <Col md='6'>
                                    <Field label="Data" name="example-text">
                                        {({ fieldProps }) => (
                                            <div className='w-100'>
                                                <DatePicker locale='pt-BR' placeholder='__/__/____'
                                                    value={this.state.date}
                                                    onChange={e => this.setState({ date: e })} />
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                                <Col md='6'>
                                    <SpotlightTarget name='createCard-transferirTempo'>
                                        <Field label="Transferir tempo da atividade anterior, iniciando a partir de" name="example-text">
                                            {({ fieldProps }) => (
                                                <div className='w-100'>
                                                    <Textfield type='time'
                                                        value={this.state.startDate}
                                                        onChange={e => {
                                                            this.setState({ startDate: e.target.value })
                                                        }} />
                                                </div>
                                            )}
                                        </Field>
                                    </SpotlightTarget>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </ModalSuricatoo>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {}
}
export default connect(mapStateToProps, {
    cardCreated,
    onStartActivityAfter,
    newMessageSuccess,
    newError
})(CreateCard)
